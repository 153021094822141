import { EtiquetaDePreco } from '@/models/EtiquetaDePreco'
import pdfMake from 'pdfmake/build/pdfmake'
import JsBarcode from 'jsbarcode'
import vfs from 'pdfmake/build/vfs_fonts.js'
import { Content, Margins, TDocumentDefinitions } from 'pdfmake/interfaces'
import print from 'print-js'
import { formatarMoeda } from '../formatadores'
import { displayNomeCompletoDoProduto } from '../produto-shareds'
import { ItemDaTabelaDePreco } from '@/models'

window.pdfMake.vfs = vfs.pdfMake.vfs;

function textToBase64Barcode(text: string) {
	const canvas = document.createElement("canvas");
	JsBarcode(canvas, text, {
		displayValue: false,
		margin: 0,
	});
	return canvas.toDataURL("image/png");
}

export async function gerarEtiquetasDeGondola(etiquetas: EtiquetaDePreco[], precoDoProduto: boolean, incluirNome: boolean, imprimirConformeRegraDeNegocioDoCaixa: boolean): Promise<string> {
	let etiquetasPorQuantidade: Content[] = []
	etiquetas.forEach(etiqueta => {
		const etiquetasDeUmaGondola = Array.from(
			{ length: etiqueta.quantidade },
			() => geraEtiquetaDeGondola(etiqueta, precoDoProduto, incluirNome, imprimirConformeRegraDeNegocioDoCaixa),
		)
		etiquetasPorQuantidade = [
			...etiquetasPorQuantidade,
			...etiquetasDeUmaGondola,
		]
	})

	let linhaDeEtiquetas: Content[][] = []
	const tamanho = 1;
	for (let i = 0; i < etiquetasPorQuantidade.length; i += tamanho) {
		const algumasEtiquetas = etiquetasPorQuantidade.slice(i, i + tamanho)
		
		linhaDeEtiquetas = [
			...linhaDeEtiquetas,
			[
				...algumasEtiquetas,
			],
		]
	}

	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			width: 297,
			height: 75,
		},
		pageMargins: [ 5, 7, 5, 0 ],
		content: linhaDeEtiquetas,
	}
	return new Promise(resolve => pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result)))
}

export function geraEtiquetaDeGondola(etiqueta: EtiquetaDePreco, precoDoProduto: boolean, incluirNome: boolean, imprimirConformeRegraDeNegocioDoCaixa: boolean): Content[] {
	const margemAbaixo = etiqueta.precos.length <= 1
		? [ 1, 1, 1, 30 ]
		: [ 1, 1, 1, 15 ]

	const moedaFormatada = etiqueta.produto.preco?.toLocaleString(
			'pt-BR', { style: 'currency', currency: 'BRL' }
		)

	if(imprimirConformeRegraDeNegocioDoCaixa) {
		return [
			{
				columns: [
					{
						width: 'auto',
						fontSize: 9,
						text: `${etiqueta.produto.eans[0] || ''}`,
					},
					{
						width: 'auto',
						fontSize: 9,
						text: ` - ${(etiqueta.produto.nomeCompleto || etiqueta.produto.nome)}`,
					},
				],
				columnGap: 3,
			},
			precoDoProduto 
				? [...etiqueta.precos
					.sort((a, b) => ordenarTiposDeClientePorOrdemAlfabetica(a, b))
					.map<Content>(preco => ({
						text: formatarPrecoQuantidade(preco, incluirNome),
						bold: true,
						margin: [ 0, 2 ],
					}))] 
				: montarNomeEPrecoDaEtiqueta(incluirNome, etiqueta, precoDoProduto),
		]
	} else {
		return [
			{
				columns: [
					{
						width: 'auto',
						fontSize: 9,
						text: `${etiqueta.produto.eans[0] || ''}`,
					},
					{
						width: 'auto',
						fontSize: 9,
						text: ` - ${(etiqueta.produto.nomeCompleto || etiqueta.produto.nome)}`,
					},
				],
				columnGap: 3,
			},
			...etiqueta.precos
				.sort((a, b) => ordenarTiposDeClientePorOrdemAlfabetica(a, b))
				.map<Content>(preco => ({
					text: formatarPrecoQuantidade(preco, incluirNome),
					bold: true,
					margin: [ 0, 2 ],
				})),
			{ 
				bold: true, 
				fontSize: 15, 
				margin: margemAbaixo as Margins, 
				text: `${precoDoProduto ? moedaFormatada : ''}`, 
			},
		]
	}
}

export function montarNomeEPrecoDaEtiqueta(incluirNome: boolean, etiqueta: EtiquetaDePreco, precoDoProduto: boolean) {
	const qtdeLimitadaDoProduto = etiqueta.precos.length > 0 && etiqueta.precos[0].qtdeLimitadaDoProduto 
		? ` - Limitações ${etiqueta.precos[0].qtdeLimitadaDoProduto}` 
		: ''

	const nome = incluirNome ? 'Padrão' : ''
	const preco = precoDoProduto ? `R$  ${formatarMoeda(etiqueta.produto.preco ? etiqueta.produto.preco : 0)}` : ''

	return nome + preco + qtdeLimitadaDoProduto
}


export async function gerarEtiquetasDeProduto(
	etiquetas: EtiquetaDePreco[],
	imprimirPrecoDoProduto: boolean,
	imprimirConformeRegraDeNegocioDoCaixa: boolean,
): Promise<string> {
	let etiquetasPorQuantidade: Content[] = []
	etiquetas.forEach(etiqueta => {
		const etiquetasDeUmProduto = Array.from(
			{ length: etiqueta.quantidade},
			() => geraEtiquetaDeProduto(etiqueta, imprimirPrecoDoProduto, imprimirConformeRegraDeNegocioDoCaixa),
		)
		etiquetasPorQuantidade = [
			...etiquetasPorQuantidade,
			...etiquetasDeUmProduto,
		]
	})

	let linhaDeEtiquetas: Content[][] = []
	const tamanho = 3;
	for (let i = 0; i < etiquetasPorQuantidade.length; i += tamanho) {
		const algumasEtiquetas = etiquetasPorQuantidade.slice(i, i + tamanho)
		const espacosVazios: Content[] = Array.from(
			{ length: tamanho - algumasEtiquetas.length },
			() => [],
		)
		linhaDeEtiquetas = [
			...linhaDeEtiquetas,
			[
				...algumasEtiquetas,
				...espacosVazios,
			],
		]
	}

	const docDefinition: TDocumentDefinitions = {
		pageSize:{
			width: 350,
			height: 55,
		},
		pageMargins: [1, 1, 1, 1],
		content: {
			table: {
				body: linhaDeEtiquetas,
			},
			layout: 'noBorders',
		},
	}
	return new Promise(resolve => {
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result))
	})
}

export async function gerarEtiquetasDePrecosDoProduto(etiquetas: EtiquetaDePreco[], precoDoProduto: boolean, incluirNome: boolean, imprimirConformeRegraDeNegocioDoCaixa: boolean): Promise<string> {
	let etiquetasPorQuantidade: Content[] = []
	etiquetas.forEach(etiqueta => {
		const etiquetasDeUmProduto = Array.from(
			{ length: etiqueta.quantidade},
			() => geraEtiquetaDePrecosDoProduto(etiqueta, precoDoProduto, incluirNome, imprimirConformeRegraDeNegocioDoCaixa),
		)
		etiquetasPorQuantidade = [
			...etiquetasPorQuantidade,
			...etiquetasDeUmProduto,
		]
	})

	let linhaDeEtiquetas: Content[][] = []
	const tamanho = 3;
	for (let i = 0; i < etiquetasPorQuantidade.length; i += tamanho) {
		const algumasEtiquetas = etiquetasPorQuantidade.slice(i, i + tamanho)
		const espacosVazios: Content[] = Array.from(
			{ length: tamanho - algumasEtiquetas.length },
			() => [],
		)
		linhaDeEtiquetas = [
			...linhaDeEtiquetas,
			[
				...algumasEtiquetas,
				...espacosVazios,
			],
		]
	}

	const docDefinition: TDocumentDefinitions = {
		pageSize:{
			width: 350,
			height: 55,
		},
		pageMargins: [1, 0, 1, 1],
		content: {
			table: {
				body: linhaDeEtiquetas,
			},
			layout: 'noBorders',
		},
	}
	return new Promise(resolve => {
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result))
	})
}

export function geraEtiquetaDePrecosDoProduto(etiqueta: EtiquetaDePreco, precoDoProduto: boolean, incluirNome: boolean, imprimirConformeRegraDeNegocioDoCaixa: boolean): Content[] {
	if(imprimirConformeRegraDeNegocioDoCaixa) {
		return [
			{
				columns: [
					{
						width: 93,
						fontSize: 5,
						bold: true,
						text: displayNomeCompletoDoProduto(etiqueta.produto).substr(0, 55),
						margin: [ 0, 0, 1, 0 ],
					},
				],
			},
			{
				margin: 0,
				image: textToBase64Barcode(etiqueta.produto.eans[0]), width: 83, height: 15,
			},
			{ 
				bold: true, 
				fontSize: 5, 
				margin: [ 0, 2, 0, 0 ],
				text: etiqueta.produto.eans[0] || '',
			},
			etiqueta.precos.length && precoDoProduto
				? etiqueta.precos
					.sort((a, b) => ordenarTiposDeClientePorOrdemAlfabetica(a, b))
					.slice(0, 2).map<Content>(preco => ({
						text: formatarPrecoQuantidade(preco, incluirNome),
						bold: true,
						margin: 0,
						fontSize: 5,
					})) 
				: montarNomeEPrecoDaEtiqueta(incluirNome, etiqueta, precoDoProduto),
		]
	} else {
		return [
			{
				columns: [
					{
						width: 93,
						fontSize: 5,
						bold: true,
						text: displayNomeCompletoDoProduto(etiqueta.produto).substr(0, 55),
						margin: [ 0, 0, 1, 0 ],
					},
				],
			},
			{
				margin: 0,
				image: textToBase64Barcode(etiqueta.produto.eans[0]), width: 83, height: 15,
			},
			{ 
				bold: true, 
				fontSize: 5,
				margin: [ 0, 2, 0, 0 ],
				text: `${etiqueta.produto.eans[0] || ''}`, 
			},
			etiqueta.precos.length
				? etiqueta.precos
					.sort((a, b) => ordenarTiposDeClientePorOrdemAlfabetica(a, b))
					.slice(0, 2).map<Content>(preco => ({
						text: formatarPrecoQuantidade(preco, incluirNome),
						bold: true,
						margin: 0,
						fontSize: 5,
					})) 
				:
				{
					text: `${'CLUBE'.padEnd(10, ' ')} - Sem preço selecionado\n${'VAREJO'.padEnd(8, ' ')} - Sem preço selecionado`,
					bold: true,
					margin: 0,
					fontSize: 5,
				},
		]
	}
}

export function geraEtiquetaDeProduto(
	etiqueta: EtiquetaDePreco,
	imprimirPrecoDoProduto: boolean,
	imprimirConformeRegraDeNegocioDoCaixa: boolean,
): Content[] {

	const qtdeLimitadaDoProduto = etiqueta.precos.length > 0 && 
			etiqueta.precos[0].qtdeLimitadaDoProduto && 
				etiqueta.precos[0].limitarProduto
		? `- Limitação ${etiqueta.precos[0].qtdeLimitadaDoProduto}` 
		: ''

	if(imprimirConformeRegraDeNegocioDoCaixa) {		
		const preco = etiqueta.precos.map(
			({ precoFinalVarejo }) => precoFinalVarejo.toLocaleString(
				'pt-BR', { style: 'currency', currency: 'BRL' },
			),
		)[0] 
		|| `R$ ${etiqueta.produto.preco} ` 

		return [
			{
				columns: [
					{
						width: 93,
						fontSize:5,
						bold: true,
						text: displayNomeCompletoDoProduto(etiqueta.produto),
						margin: [ 1, 1, 1, 1 ],
					},
				],
			},
			{
				columns: [
					{
						margin: 0,
						image: textToBase64Barcode(etiqueta.produto.eans[0]), width: 83, height: 15,
					},
				],
			},
			{
				columns: [
					{ 	
						bold: true,
						fontSize: 4, 
						width: 'auto',
						margin: [ 0, 2, 0, 0 ],
						text:`${etiqueta.produto.eans[0]}` || '',
					},
				],
			},
			{
				columns: [
					{ 
						bold: true,
						fontSize: 4, 
						width: 'auto',
						margin: [ 0, 2, 2, 0 ],
						text: imprimirPrecoDoProduto 
							? `${preco}` 
							: '', 
					},
					{ 
						fontSize: 4, 
						width: 'auto',
						margin: [ 0, 2, 0, 0 ],
						text: imprimirPrecoDoProduto 
							? qtdeLimitadaDoProduto || ''
							: '',
					},
				],
			},
		]
	} else {
		const preco = imprimirPrecoDoProduto
			? etiqueta.produto.preco?.toLocaleString(
				'pt-BR', { style: 'currency', currency: 'BRL' }
			)
			: etiqueta.precos.map(
				({ precoFinalVarejo }) => precoFinalVarejo.toLocaleString(
					'pt-BR', { style: 'currency', currency: 'BRL' },
				),
			)[0]

		return [
			{
				columns: [
					{
						width: 93,
						fontSize:5,
						bold: true,
						text: displayNomeCompletoDoProduto(etiqueta.produto),
						margin: [ 1, 1, 1, 1 ],
					},
				],
			},
			{
				columns: [
					{
						margin: 0,
						width: 83, 
						height: 15,
						image: textToBase64Barcode(etiqueta.produto.eans[0]),
					},
				],
			},
			{
				columns: [
					{ 	
						bold: true,
						fontSize: 4, 
						width: 'auto',
						margin: [ 0, 2, 0, 0 ],
						text:`${etiqueta.produto.eans[0]}` || '',
					},
				],
			},
			{
				columns: [
					{ 
						bold: true,
						fontSize: 4, 
						width: 'auto',
						margin: [ 0, 2, 2, 0 ],
						text: preco ? preco : '', 
					},
					{ 
						fontSize: 4, 
						width: 'auto',
						margin: [ 0, 2, 0, 0 ],
						text:  qtdeLimitadaDoProduto  || '',
					},
				],
			},
		]
	}
}

export async function imprimirEtiquetasDaGondola(etiquetas: EtiquetaDePreco[], precoDoProduto: boolean, incluirNome: boolean, imprimirConformeRegraDeNegocioDoCaixa: boolean): Promise<void> {
	const pdf: string = await gerarEtiquetasDeGondola(etiquetas, precoDoProduto, incluirNome, imprimirConformeRegraDeNegocioDoCaixa)
	return new Promise((resolve, reject) => {
		print({
			printable: pdf,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

export async function imprimirEtiquetasDeProduto(etiquetas: EtiquetaDePreco[], precoDoProduto: boolean, imprimirConformeRegraDeNegocioDoCaixa: boolean): Promise<void> {
	const pdf: string = await gerarEtiquetasDeProduto(etiquetas, precoDoProduto, imprimirConformeRegraDeNegocioDoCaixa)
	return new Promise((resolve, reject) => {
		print({
			printable: pdf,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

export async function imprimirEtiquetasDePrecosDoProduto(etiquetas: EtiquetaDePreco[], precoDoProduto: boolean, incluirNome: boolean, imprimirConformeRegraDeNegocioDoCaixa: boolean): Promise<void> {
	const pdf: string = await gerarEtiquetasDePrecosDoProduto(etiquetas, precoDoProduto, incluirNome, imprimirConformeRegraDeNegocioDoCaixa)
	return new Promise((resolve, reject) => {
		print({
			printable: pdf,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

function formatarPrecoQuantidade(preco: ItemDaTabelaDePreco, incluirNome: boolean) {
	const qtdeLimitadaDoProduto = preco.qtdeLimitadaDoProduto ? ` - Limitação ${preco.qtdeLimitadaDoProduto}` : ''
	const nomeDoPlano = preco.tabela.tipoDeCliente?.nome || 'Varejo'
	const margem = nomeDoPlano?.toUpperCase() !== 'CLUBE' ? 14 : 15
	const eVarejo = nomeDoPlano === 'Varejo'

	return incluirNome
		? `${nomeDoPlano.toUpperCase().padEnd(margem - nomeDoPlano.length, ' ')}-`
		+ ` R$ ${formatarMoeda(preco.precoFinalVarejo)}`
		+ (eVarejo
			? ''
			: qtdeLimitadaDoProduto
		)
		: ` R$${formatarMoeda(preco.precoFinalVarejo)}`
		+ (eVarejo
			? ''
			: qtdeLimitadaDoProduto
		)
}
  

function ordenarTiposDeClientePorOrdemAlfabetica(itemAtual: ItemDaTabelaDePreco, proximoItem: ItemDaTabelaDePreco) {
	const tipoDeClienteAtual = itemAtual.tabela.tipoDeCliente?.nome.toUpperCase() || 'VAREJO'
	const proximoTipoDeCliente = proximoItem.tabela.tipoDeCliente?.nome.toUpperCase() || 'VAREJO'
	return tipoDeClienteAtual === proximoTipoDeCliente ? 0 : (tipoDeClienteAtual > proximoTipoDeCliente ? 1 : -1)
}